<template>
  <section>
    <div id="app">
      <mapir 
        :apiKey="mapirToken" 
        :center="coordinates" 
        @load="onMapLoaded" 
        @click="mapOnClick"
        :zoom="13"
        >
        <mapNavigationControl position="top-right" />
        <mapScaleControl position="top-right" />
        <mapMarker
          :coordinates.sync="markerCoordinates"
          color="blue"
          :draggable="true"
          @dragend="getlatLng"
        >
        </mapMarker>
      </mapir>
    </div>

    <div class="mt-1 text-center">
      <v-btn color="primary" large class="col-12" @click="submit()" :loading="submit_loading" :disabled="submit_loading">
        {{$t('Save changes')}}
      </v-btn>  
    </div>

  </section>
</template>

<script>
import {
  mapNavigationControl,
  mapScaleControl
} from "mapir-vue";

import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
    mapNavigationControl,
    mapScaleControl
  },
  data() {
    return {
      map: null,
      submit_loading: false,

      coordinates: [51.420296, 35.732379],
      markerCoordinates: [51.420296, 35.732379],
      
      mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIn0.eyJhdWQiOiIxNzExNSIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIiwiaWF0IjoxNjQ1MjY3NTI5LCJuYmYiOjE2NDUyNjc1MjksImV4cCI6MTY0NzY4NjcyOSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.sw_1-g4cTn-J__N6nhChXOJVJNVUu8Spm-vemedB0_oz3hvCJTE4oxaCsCxCuRruUVspmgeGKgBgMh4-xtEhrwB_IVuIf5OrJSA1niTccaXGyr6k5VNpg_dHEPstIOJRzAWKsNyzlf2xUK0PkdulfI2AU0dtWFuuPtR12iZEQpZNEwoaOPD1A6tTb58HWBUoUltKSZk96QtoUlND6uuZKKTwaTYBPPwcL7v7JxDlxGEvSkoNWr-R8rzI48MAepXEBSgeewXIcOZ7-EQVHkbhjLyWu4GKJJo9RBqzEYYcVRewNDhqnAJIdPPyzsG7v5-DI2Mbmgo0lUDaD51XWpm2LA",

    }
  },
  methods: {
    submit() {
      this.submit_loading = true;
    },
    async onMapLoaded(event){
      var map = event.map;
      await map.flyTo({
        center: this.coordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 5000,
      })
    },
    async getlatLng(event){
      var map = event.map;
      this.markerCoordinates = [event.marker._lngLat.lng,event.marker._lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })

    },
    async mapOnClick(event){
      // console.log(event.actualEvent.lngLat);
      var map = event.map;
      this.markerCoordinates = [event.actualEvent.lngLat.lng , event.actualEvent.lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    var LonLat = this.$route.params.coordinates.split(',');
    this.coordinates = [parseFloat(LonLat[0]), parseFloat(LonLat[1])]
    this.markerCoordinates = [parseFloat(LonLat[0]), parseFloat(LonLat[1])]
  },
}
</script>

<style>
#app {
  width: 100%;
  height: 70vh;
}
</style>
